<template>
  <div class="typing-start">
    <div class="load-font">1</div>
    <div class="page-main">
      <div class="page-article">
        <p class="article-head">
          <span class="cancel-btn">开始输入时开始计时！</span>
          <span class="article-title">{{ article.title }}</span>
          <span class="article-set">
            <span>类别：{{ articleType }}</span>
            <span>设定时间：<span>{{ articleInfo.setTime }}分钟</span></span>
          </span>
        </p>
        <!--        <p class="article-tip">-->
        <!--          开始输入时开始计时！-->
        <!--        </p>-->
        <ArticleWhole
          ref="typing"
          :key="typingKey"
          :whole-data="wholeData"
          :whole-data-arr="wholeDataArr"
          :row-length="rowLength"
          :is-ch-en="isChEn"
        />
      </div>
      <div class="page-record">
        <record
          ref="record"
          :key="recordKey"
          @continue="continueTyping"
          @end="endTyping"
          @reset="reTyping"
        />
      </div>
      <result
        :show="showResult"
        :num="num"
        :set-time="setTime"
        :speed="speed"
        :accuracy="accuracy"
        :ch-en="isChEn"
        :text="resultText"
        @again="reTyping"
      />
    </div>
    <index-bottom />
  </div>
</template>

<script>
import ArticleWhole from '../../components/article/ArticleWhole'
import indexBottom from '../../components/indexBottom'
import record from './components/record'
import result from './components/result'
import { mapState } from 'vuex'

import { getArticle } from '@/api/typing/article'
import { addRecord } from '@/api/typing/record'
import { MessageBox } from 'element-ui'

export default {
  name: 'TypingStart',
  components: {
    indexBottom,
    record,
    result,
    ArticleWhole
  },
  created() {
    this.articleId = this.$route.query.id
    this.$store.commit('SET_IS_START', false)
    this.getArticle()
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      articleTypeOptions: state => state.dict.dictTree.article_type['arr'],
      recordSpeedAssessmentCh: state => state.dict.dictTree.record_speed_assessment_ch['arr'],
      recordSpeedAssessmentEn: state => state.dict.dictTree.record_speed_assessment_en['arr'],
      articleInfo: state => state.typing.article,
      recordInfo: state => state.typing.record,
      recordArr: state => state.typing.recordArr
    })
  },
  data() {
    return {
      article: {},
      articleId: '',
      articleType: '',
      wholeData: '',
      wholeDataArr: [],
      rowLength: 10, // 每一行的长度
      enMinLength: 50, // 英文文章每行理论最短长度 --- 要求 min > max / 2
      enMaxLength: 70, // 英文文章每行理论最长长度
      isChEn: false, // 中英文，默认false，英文
      showResult: false,
      num: 0,
      setTime: '0',
      speed: 0,
      accuracy: 0,
      resultText: '',
      typingKey: 0,
      recordKey: 0
    }
  },
  methods: {
    getArticle() {
      getArticle(this.articleId).then(response => {
        console.log(response)
        if (response.code === 55555) {
          this.$message.info(response.msg)
          this.$router.push('/typingSetting')
        } else {
          this.article = response.data
          this.articleType = this.articleTypeOptions.filter(type => type.value === this.article.type)[0].label
          if (this.article.lang === '0') {
            this.isChEn = true
            this.rowLength = 35
          } else if (this.article.lang === '1') {
            this.isChEn = false
            this.rowLength = 10
          }
          this.dealArticle()
        }
      })
    },
    dealArticle() {
      this.wholeData = this.article.content
      this.wholeDataArr = []
      if (this.wholeData.indexOf('\n') !== -1) {
        this.dealNewLineData(this.wholeData)
      } else {
        this.dealParagraphData(this.wholeData)
      }
    },
    // 当前字符有换行符时的处理 递归处理有换行符的数据
    dealNewLineData(val) {
      const index = val.indexOf('\n')
      if (index !== -1) {
        this.dealParagraphData(val.substring(0, index))
        this.dealNewLineData(val.substr(index + 1))
      } else {
        this.dealParagraphData(val)
      }
    },
    // 每一段的处理（中英文）无换行符
    dealParagraphData(val) {
      this.enMinLength = 65
      this.enMaxLength = 75
      // todo 去除开头的首行缩进，开头和结尾的空格
      let spaceLength = this.getSpaceLength(val)
      val = val.trim()
      // 中文处理
      if (this.isChEn) {
        spaceLength = 0
        const rowNum = Math.ceil(val.length / this.rowLength)
        for (let i = 0; i < rowNum; i++) {
          if (i === rowNum - 1) {
            this.wholeDataArr.push({
              content: val.substr(i * this.rowLength).trim(),
              spaceLength: spaceLength
            })
          } else {
            this.wholeDataArr.push({
              content: val.substr(i * this.rowLength, this.rowLength).trim(),
              spaceLength: spaceLength
            })
          }
        }
      }
      // 英文处理
      else {
        this.enMinLength = this.enMinLength - spaceLength
        this.enMaxLength = this.enMaxLength - spaceLength
        const regx = /\s/g
        const regxArr = []
        // 找出所有空格的index
        while (regx.test(val)) {
          regxArr.push(regx.lastIndex - 1)
        }
        // 处理这个index数组，现有两种思路

        // 1、根据每行10个单词 找出可以有多少行
        // const num = Math.ceil(regxArr.length / this.rowLength)
        // if (num === 0) {
        //   this.wholeDataArr.push(val)
        //   return
        // }
        // for (let i = 0; i < num; i++) {
        //   if (i === num - 1) {
        //     let j = i * this.rowLength - 1
        //     let index = -1
        //     if (j < 0) index = 0
        //     else index = regxArr[j] + 1
        //     this.wholeDataArr.push(val.substring(index).trim())
        //   } else {
        //     let j = i * this.rowLength - 1
        //     let k = (i + 1) * this.rowLength - 1
        //     let index1 = -1
        //     let index2 = -1
        //     if (j < 0) index1 = 0
        //     else index1 = regxArr[j] + 1
        //     index2 = regxArr[k] + 1
        //     this.wholeDataArr.push(val.substring(index1, index2).trim())
        //   }
        // }

        // 2、一个单词一个单词走，长度满足就下一行，不满足就再加下一个单词
        if (regxArr.length === 0) {
          // this.wholeDataArr.push(val.trim())
          const lastValue = this.dealLongValue(val.trim(), spaceLength)
          if (lastValue !== '') {
            this.wholeDataArr.push({
              content: lastValue.trim(),
              spaceLength: spaceLength
            })
          }
          return
        }
        if (regxArr.length === 1) {
          const tempValue = this.judgePrepareValue('', val.substring(0, regxArr[0]), spaceLength)
          const lastValue = this.judgePrepareValue(tempValue, val.substring(regxArr[0]), spaceLength)
          if (lastValue !== '') {
            this.wholeDataArr.push({
              content: lastValue.trim(),
              spaceLength: spaceLength
            })
          }
        }
        let prepareValue = ''
        for (let i = 0; i < regxArr.length; i++) {
          if (i === 0) {
            prepareValue = this.judgePrepareValue(prepareValue, val.substring(0, regxArr[i]), spaceLength)
          } else if (i === regxArr.length - 1) {
            prepareValue = this.judgePrepareValue(prepareValue, val.substring(regxArr[i - 1], regxArr[i]), spaceLength)
            const lastValue = this.judgePrepareValue(prepareValue, val.substring(regxArr[i]), spaceLength)
            if (lastValue !== '') {
              this.wholeDataArr.push({
                content: lastValue.trim(),
                spaceLength: spaceLength
              })
            }
          } else {
            prepareValue = this.judgePrepareValue(prepareValue, val.substring(regxArr[i - 1], regxArr[i]), spaceLength)
          }
        }
      }
    },
    // 判断前一个字符串 和 后面的字符串 的长度
    judgePrepareValue(prepareValue, indexValue, spaceLength) {
      prepareValue = this.dealLongValue(prepareValue, spaceLength)

      const pLen = prepareValue.length
      const iLen = indexValue.length
      const len = pLen + iLen
      let result = ''
      if (len < this.enMinLength) {
        result = prepareValue + indexValue + ''
      } else if (len < this.enMaxLength) {
        const pushValue = prepareValue + indexValue + ''
        this.wholeDataArr.push({
          content: pushValue.trim(),
          spaceLength: spaceLength
        })
        result = ''
      } else {
        this.wholeDataArr.push({
          content: prepareValue.trim(),
          spaceLength: spaceLength
        })
        result = this.dealLongValue(indexValue, spaceLength)
      }
      return result
    },
    // 处理过长的字符串
    dealLongValue(longValue, spaceLength) {
      const iLen = longValue.length
      let result = ''
      if (iLen < this.enMinLength) {
        result = longValue
        return result
      } else if (iLen < this.enMaxLength) {
        this.wholeDataArr.push({
          content: longValue.trim(),
          spaceLength: spaceLength
        })
        result = ''
        return result
      } else {
        this.wholeDataArr.push({
          content: longValue.substr(0, this.enMaxLength).trim(),
          spaceLength: spaceLength
        })
        return this.dealLongValue(longValue.substring(this.enMaxLength), spaceLength)
      }
    },
    getSpaceLength(val) {
      let l = 0
      if (val && val !== '') {
        for (let i = 0; i < val.length; i++) {
          if (/[\s]/.test(val.substr(i, 1))) {
            l = i + 1
          } else break
        }
      }
      return l
    },
    continueTyping(val) {
      if (val) {
        this.$refs.typing.continueTyping()
      } else {
        this.$refs.typing.stopTyping()
      }
    },
    endTyping() {
      this.$refs.typing.stopTyping()
      this.setTime = this.articleInfo.setTime + ''
      this.speed = this.getSpeed()
      this.accuracy = this.getAccuracy()
      addRecord({
        articleId: this.articleInfo.articleId,
        totalChar: this.recordInfo.totalChar,
        correctChar: this.recordInfo.correctChar,
        errorChar: this.recordInfo.errorChar,
        backspaceNum: this.recordInfo.backspace,
        setTime: this.setTime,
        actualTime: this.articleInfo.setTime * 60,
        speed: this.speed,
        accuracy: this.accuracy
      }).then(response => {
        console.log(response.data)
        this.num = response.data
        const assessArr = this.isChEn ? this.recordSpeedAssessmentCh : this.recordSpeedAssessmentEn
        for (let i = 0; i < assessArr.length; i++) {
          if (this.speed < assessArr[i].value) {
            this.resultText = assessArr[i].label
            break
          }
          this.resultText = assessArr[assessArr.length - 1].label
        }
        this.showResult = true
      })
    },
    getSpeed() {
      let speed = 0
      let num = 0
      if (!Array.isArray(this.recordArr) || this.recordArr.length === 0) return speed
      this.recordArr.forEach(record => {
        num += record.correctChar
      })
      const time = this.articleInfo.setTime * 60

      if (num !== undefined && num !== null && num > 0 && time > 0) {
        speed = Math.floor(num * (60 / time))
      }
      return speed
    },
    getAccuracy() {
      let accuracy = 0
      // const tNum = this.recordInfo.totalChar
      // const cNum = this.recordInfo.correctChar
      let cNum = 0
      let tNum = 0
      if (!Array.isArray(this.recordArr) || this.recordArr.length === 0) return accuracy
      this.recordArr.forEach(record => {
        cNum += record.correctChar
        tNum += record.totalChar
      })
      if (tNum !== undefined && tNum !== null && cNum !== undefined && cNum !== null) {
        if (tNum > 0 && cNum > 0) {
          accuracy = Math.floor(cNum / tNum * 100)
        }
      }
      return accuracy
    },
    beforeunload(event) {
      console.log(event)
      MessageBox.confirm('刷新将丢失当前打字记录，是否继续？', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        this.$store.commit('SET_ARTICLE', {})
        this.$store.commit('SET_RECORD', {})
        this.$store.commit('SET_IS_START', false)
        this.$router.push('/typingSetting')
      }).catch(() => {
      })
    },
    reTyping() {
      this.$store.commit('SET_IS_START', false)
      // this.$refs.typing.handleWholeDataArr(this.wholeDataArr)
      // this.$refs.record.init()
      this.recordKey++
      this.typingKey++
      this.showResult = false
    }
  }
}
</script>

<style scoped lang="less">
.typing-start {
  //margin-top: 80px;
  padding-top: 80px;

  .load-font {
    font-family: "typing-custom";
    content: ".";
    position: absolute;
    color: #edf1f7;
  }

  .page-main {
    //padding: 5px 315px;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 10px;

    .page-article {
      width: 79%;
      float: left;
      background-color: #fff;
      border-radius: 10px;
      .article-head {
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        .cancel-btn,.article-title,.article-set  {
          display: inline-block;
          width: 300px;
        }
        .cancel-btn {
          color: #c49f66;
          font-size: 12px;
          padding-right: 120px;
        }
        .article-title {
          //font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .article-set {
          color: #c0bdbd;
          font-size: 12px;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
        }
      }
      .article-tip {
        height: 50px;
        line-height: 50px;
        background-color: #fcf8f5;
        color: #c49f66;
        font-size: 12px;
      }
    }

    .page-record {
      width: 19%;
      height: 200px;
      float: right;
      border-radius: 10px;
    }
  }
}
</style>
