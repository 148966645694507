<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="500"
    :show-close="false"
    :close-on-click-modal="false"
    :lock-scroll="true"
    class="typing_result"
  >
    <div class="result_bg">
      <!--      <img src="../../../assets/images/typing_result1.png" alt="" class="result_bg1">-->
      <img src="../../../assets/images/typing_result2.png" alt="" class="result_bg2">
    </div>

    <div class="time-tip-box">
      <div class="img-box">
        <img src="../../../assets/images/typing_daojishi.png">
      </div>
      <div class="time-tip">设定时间已到！</div>
    </div>
    <p class="result-tip">您已经超过<span class="num-text">{{ num }}%</span>的学生</p>
    <p class="evaluate">{{ text }}</p>
    <p class="result-box">
      <span class="time">时间：<span class="time-num">{{ setTime }}</span><span class="result-unit">分钟</span></span> |
      <span class="speed">速度：<span class="speed-num">{{ speed }}</span><span class="result-unit">{{ chEn ? 'WPM' : 'KPM' }}</span></span> |
      <span class="accuracy">成功率：<span class="accuracy-num">{{ accuracy }}</span><span class="result-unit">%</span></span>
    </p>
    <span slot="footer" class="dialog-footer">
      <el-button class="footer-btn" round @click="again">再来一次</el-button>
      <el-button id="endBtn" class="footer-btn-last" type="primary" round @click="end">结 束</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'TypingSetting',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    num: {
      type: Number,
      require: true
    },
    setTime: {
      type: String,
      require: true
    },
    speed: {
      type: Number,
      require: true
    },
    accuracy: {
      type: Number,
      require: true
    },
    chEn: {
      type: Boolean,
      require: true
    },
    text: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialogVisible = newVal
        if (newVal) {
          const timer = setInterval(() => {
            const btn = document.getElementById('endBtn')
            if (btn) {
              btn.focus()
              clearInterval(timer)
            }
          }, 10)
        }
      }
    }
  },
  methods: {
    again() {
      // this.$router.push('/typingSetting')
      this.$emit('again')
    },
    end() {
      this.$router.push('/typingSetting')
    }
  }
}
</script>

<style scoped lang="less">
.typing_result {
  .result_bg {
    width: 100%;
    height: 100px;
    position: relative;
    background-image: url("../../../assets/images/typing_result1.png");
    background-size: 50% 100%;
    .result_bg2 {
      width: 100px;
      border-radius: 10px 10px 0 0;
      position: absolute;
      top: -28px;
      left: 50%;
      margin-left: -50px;
      z-index: 999;
    }
  }
  .time-tip-box {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: #fff7e8;
    .img-box, .time-tip {
      position: absolute;
      top: 10px;
      left: 35%;
    }
    .img-box {
      width: 40px;
      height: 40px;
      margin-left: -40px;
      img {
        width: 90%;
        height: 90%;
        margin: 5%;
      }
    }
    .time-tip {
      height: 40px;
      font-size: 25px;
      line-height: 40px;
      font-weight: 530;
      padding: 0 15px;
      color: #ebc32a;
    }
  }
  .result-tip {
    font-size: 21px;
    padding: 15px 0;
    color: #bbbbbd;
  }
  .evaluate {
    font-size: 23px;
    font-weight: 540;
    color: #39A5EF;
    margin: 15px 0;
  }

  .result-box {
    font-size: 16px;
    line-height: 60px;
    margin: 29px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: #ccc;
  }
  .time,
  .speed,
  .accuracy {
    display: inline-block;
    //width: 150px;
    margin: 5px 10px;
    color: #000;
  }

  .time-num,
  .speed-num,
  .accuracy-num {
    font-size: 30px;
    font-weight: bold;
    color: #39A5EF;
  }
  .result-unit {
    color: #ccc;
  }
  .num-text {
    color: #39A5EF;
  }
  .footer-btn,
  .footer-btn-last {
    width: 110px;
  }
  .footer-btn-last {
    margin-left: 50px;
  }

  ::v-deep .el-dialog {
    margin-top: 10vh !important;
    width: 500px;
    border-radius: 10px;
  }
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
    margin-bottom: 30px;
  }
  ::v-deep .el-dialog__footer {
    padding: 25px 20px 20px;
    text-align: center !important;
  }
}
</style>
