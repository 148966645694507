<template>
  <span
      :class="{
         'art-col': !spaceFlag,
         'art-col-space': spaceFlag,
         'col-error': arriveFlag && !correctFlag && !spaceFlag,
         'col-error-space': arriveFlag && !correctFlag && spaceFlag,
         'col-correct':  arriveFlag && correctFlag && !spaceFlag,
         'col-correct-space':  arriveFlag && correctFlag && spaceFlag
      }"
  >
    {{item}}
  </span>
</template>
<script>
export default {
  name: 'ArticleCol',
  props: {
    colData: {
      type: Object,
      require: true
    }
  },
  watch: {
    colData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.handleColData(val)
      }
    }
  },
  data() {
    return {
      item: '',
      arriveFlag: false,
      correctFlag: true,
      spaceRegx: /\s/,
      spaceFlag: false
    }
  },
  methods: {
    handleColData(val) {
      this.item = val.item
      this.arriveFlag = val.arriveFlag
      this.correctFlag = val.correctFlag
      this.spaceFlag = false
      if (this.spaceRegx.test(this.item)) {
        this.item = '_'
        this.spaceFlag = true
      }
    }
  }
}
</script>
<style scoped lang="less">
.art-col {
  display: inline-block;
  min-width: 12px;
  color: #999999;
  font-size: 20px;
  font-weight: initial;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.col-correct {
  color: #009900;
}
.col-error {
  color: #fff;
  background-color: #ff3333;
}
.art-col-space {
  display: inline-block;
  min-width: 12px;
  color: #ffffff;
  font-size: 20px;
  font-weight: initial;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.col-correct-space {
  color: #ffffff;
}
.col-error-space {
  color: #ff3333;
  background-color: #ff3333;
}
</style>
