<template>
  <!--  登陆以后的底部-->
  <div class="IndexFooter">
    <!--    上-->
    <div class="Footer">
      <div class="FooterContent">
        <!--        1-->
        <div class="ImgLeft">
          <div class="picBox">
            <!--          教育-->
            <div class="Img">
              <img src="../assets/images/logo1.png" alt="">
            </div>
            <div class="name">教学管理云平台2.0</div>
          </div>
          <!--          文字-->
          <div class="Content">
            <p>践行技术改变命运的初心</p>
            <p>打造IT职业教育的一面旗帜</p>
          </div>
        </div>
        <div class="stick" />
        <!--        2-->
        <div class="Contact">
          <!--          联系-->
          <div class="Cont">联系我们</div>
          <!--          地址-->
          <div class="Adress">
            <p>公司地址：{{ contactUs[0].label }}</p>
            <p> 联系电话：{{ contactUs[1].label }}</p>
            <p>商务合作：{{ contactUs[2].label }}</p></div>
        </div>
        <div class="stick" />
        <!--        4-->
        <div class="link">
          <div class="Link">
            <p>友情链接</p>
          </div>
          <!--     课堂外网   -->
          <div class="External">
            <ul>
              <li v-for="i in linkList" :key="i.value"><a :href="i.value" target="_blank">{{ i.label }}</a></li>
            </ul>
          </div>
        </div>
        <div class="stick" />
        <!--        3-->
        <div class="Public">
          <!--          二维码-->
          <div class="QrCode">
            <img src="../assets/images/subscription.jpg" alt="">
          </div>
          <!--          文字-->
          <div class="PubContent">
            <p>微信公众号</p>
          </div>
        </div>

      </div>
    </div>
    <!--    线-->
    <div class="StraightLine" />
    <!--    下-->
    <div class="FooterF">
      <div>Copyright © 2017-2025 石家庄天亮教育科技有限公司 备案号:冀ICP备17020003号</div>
    </div>
  </div>
</template>

<script>
// import {mapState} from "vuex";

export default {
  name: 'IndexBottom'
}
</script>

<style scoped lang="less">
.IndexFooter {
  position: relative;
  //background-color: rgba(58, 63, 71, 1);
  background: #041D47;

  .Footer {
    height: 179px;
    background: #041D47;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    //height: 366px;
    //background: rgba(58, 63, 71, 1);
    //内容
    .FooterContent {
      width: 1200px;
      margin: auto;
      //height: 366px;
      //左
      .ImgLeft {
        width: 255px;
        float: left;
        //overflow: hidden;
       .picBox {
         height: 92px;
         //教育
         .Img {
           float: left;
           width: 45px;
           height: 46px;
           text-align: left;
           margin-top: 46px;
           margin-right: 10px;
           margin-left: 9px;
           img {
             width: 45px;
             height: 46px;
           }
         }
         .name {
           float: left;
           margin-left: 10px;
           margin-top: 64px;
           font-size: 16px;
           font-family: HYk2gj;
           color: #4C97FD;
           line-height: 16px;
           height: 17px;
         }
       }
        //文字
        .Content {
          padding-top: 13px;
          width: 167px;
          height: 38px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #667385;
          p {
            text-align: center;
          }
        }
      }
.stick {
  float: left;
  margin-top: 35px;
  width: 1px;
  height: 113px;
  background: #454F5C;
}
      //中间
      .Contact {
        width: 473px;
        float: left;
        //联系
        .Cont {
          padding-top: 30px;
          margin-left: 60px;
          width: 64px;
          height: 51px;
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #8E9BAC;
          line-height: 21px;
        }
        //地址
        .Adress {
          margin-top: 20px;
          margin-left: 60px;
          //width: 352px;
          //height: 101px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #667385;
          p {
            text-align: left;
            margin-bottom: 10px;
          }
        }
      }
      //  友情链接
      .link {
        width: 309px;
        float: left;
        .Link {
          p {
            text-align: left;
            padding-top:30px;
            margin-left: 60px;
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #8E9BAC;
            line-height: 21px;
          }
        }
        //课堂外网
        .External {
          margin-top: 10px;
          ul {
            margin-top: 20px;
            margin-left: 60px;
            overflow: hidden;
            li:first-child,
            li:nth-child(3){
              padding-left: 0;
            }
            li:nth-child(2){
              padding-right: 25px;
            }
            li{
              float: left;
              //width: 60px;
              height: 18px;
              margin-bottom: 10px;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #C9D3E2;
              padding-left: 25px;
              a{
                color: #667385;
                &:hover {
                  color: #C9D3E2;
                  text-decoration: underline;
                }
              }

            }

          }
        }
      }
      //右
      .Public {
        float: left;
        //二维码
        .QrCode {
          margin-top: 30px;
          margin-left: 54px;
          img {
            width: 106px;
            height: 106px;
          }
        }
        //文字
        .PubContent {
          padding-top:10px;
          padding-left: 57px;
          line-height: 21px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #667385;
        }

      }

    }
  }

  .StraightLine {
  margin:0 auto;
    width: 1200px;
    height: 1px;
    background: #454F5C;

  }

  .FooterF {
    width: 100%;
    //height: 69px;
    background: #041D47;
    text-align: center;
    padding-bottom: 30px;
    div {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #667385;
      text-align: center;
      margin-top: 20px;
      //margin-bottom: 30px;
      //height: 18px;

    }
  }
}
</style>
<!--<template>-->
<!--    <div class="bottomBg">-->
<!--        <div class="box">-->
<!--            <div class="bottomLeft">-->
<!--                <div class="logo"><img src="../assets/images/logo.png" style="width: 40px;height: 40px"/><p>教学学习平台</p></div>-->
<!--                <div class="tip" style="text-align: left">友情链接</div>-->
<!--                <div class="link">-->
<!--                    <ul>-->
<!--                        <li v-for="i in linkList" :key="i.value"><a :href="i.value"  target="_blank">{{i.label}}</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div class="info" style="text-align: left">Copyright © 2017-2025 石家庄天亮教育科技有限公司  备案号:冀ICP备17020003号</div>-->
<!--            </div>-->
<!--            <div class="bottomRight">-->
<!--                <div class="code">-->
<!--                    <img src="../assets/images/ERCode.png" alt="二维码">-->
<!--                </div>-->
<!--                <div class="message">扫码关注官方公众号</div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'indexBottom',
    computed:{
      ...mapState({
        linkList:state=>state.dict.dictTree.ea_link['arr'],
        contactUs: state => state.dict.dictTree.contact_us['arr'],
      })
    },
    created() {
      // console.log(123)
      // console.log(this.contactUs)
    },

    data(){
      return{
        // linkList:[
        //   {name:'天亮云课堂',url:'http://course.myhope365.com/index'},
        //   {name:'天亮教育官网',url:'http://www.myhope365.com/index'},
        //   {name:'IT毕业设计',url:''},
        //   {name:'尚云科技',url:'http://shangyuninfo.com/'},
        // ]
      }
    }
  }
</script>

<!--<style scoped lang="less">-->
<!--    .bottomBg{-->
<!--        position: relative;-->
<!--        bottom: 0;-->
<!--        min-width: 1290px;-->
<!--        width: 100%;-->
<!--        height: 215px;-->
<!--        background-image: url("../assets/images/bottomBg.png");-->

<!--        .box{-->
<!--            display: flex;-->
<!--            justify-content: space-around;-->
<!--            align-items: center;-->
<!--            height: 215px;-->
<!--            margin: 0 auto;-->
<!--            width: 92%;-->
<!--            .bottomLeft{-->
<!--                padding-left: 20px;-->
<!--                width: 500px;-->
<!--                font-family: Microsoft YaHei;-->
<!--                font-weight: 400;-->
<!--                color: #FFFFFF;-->
<!--                >.logo{-->
<!--                    font-size: 30px;-->
<!--                    font-family: JBeiWeiKaiSu;-->
<!--                    display: flex;-->
<!--                    p{-->
<!--                        margin: 0 10px;-->
<!--                    }-->
<!--                }-->
<!--                >.tip{-->
<!--                    font-size: 16px;-->
<!--                    margin-top: 20px;-->
<!--                }-->
<!--                >.link{-->
<!--                    margin: 15px 0 10px 0;-->
<!--                    font-size: 12px;-->
<!--                    width: 400px;-->
<!--                    ul{-->
<!--                        display: flex;-->
<!--                        padding-left: 0;-->
<!--                        justify-content: space-between;-->
<!--                        li{-->
<!--                            list-style: none;-->
<!--                            a{-->
<!--                                color: #FFFFFF;-->
<!--                            }-->
<!--                        }-->
<!--                    }-->
<!--                }-->
<!--                .info{-->
<!--                    font-size: 12px;-->
<!--                }-->
<!--            }-->
<!--            .bottomRight{-->
<!--                padding-right: 70px;-->
<!--                flex-shrink: 0;-->
<!--                font-size: 14px;-->
<!--                font-family: Microsoft YaHei;-->
<!--                font-weight: 400;-->
<!--                color: #FFFFFF;-->
<!--                text-align: center;-->
<!--            }-->
<!--        }-->

<!--    }-->

<!--</style>-->
