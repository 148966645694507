var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:{
       'art-col': !_vm.spaceFlag,
       'art-col-space': _vm.spaceFlag,
       'col-error': _vm.arriveFlag && !_vm.correctFlag && !_vm.spaceFlag,
       'col-error-space': _vm.arriveFlag && !_vm.correctFlag && _vm.spaceFlag,
       'col-correct':  _vm.arriveFlag && _vm.correctFlag && !_vm.spaceFlag,
       'col-correct-space':  _vm.arriveFlag && _vm.correctFlag && _vm.spaceFlag
    }},[_vm._v(" "+_vm._s(_vm.item)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }