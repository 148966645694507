<template>
  <div class="art-whole">
    <div class="art-scroll">
      <div v-for="(rowData, index) in articleExArr" :key="index" class="art-row-box">
        <ArticleRow
          :row-data="rowData"
          :row-index="index"
          :row-index-init="currentIndexInit"
          :current-index="currentIndex"
          :is-ch-en="isChEn"
          @next="nextRow"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ArticleRow from './ArticleRow'
export default {
  name: 'ArticleWhole',
  components: {
    ArticleRow
  },
  props: {
    wholeData: {
      type: String,
      require: true
    },
    wholeDataArr: {
      type: Array,
      require: true
    },
    rowLength: {
      type: Number,
      default: 60
    },
    isChEn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      articleExArr: [],
      currentIndex: -1,
      tempIndex: -1,
      currentIndexInit: '',
      spaceStatus: false,
      docScroll: -1
    }
  },
  computed: {

  },
  watch: {
    wholeDataArr: {
      deep: true,
      immediate: true,
      handler(val) {
        this.handleWholeDataArr(val)
      }
    }
  },
  created() {
    this.stopF5Refresh()
    document.body.onkeydown = function(event) {
      var e = window.event || event
      if (event.target.nodeName === 'INPUT') return
      if (e.preventDefault) {
        e.preventDefault()
      } else {
        window.event.returnValue = false
      }
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeunloadHandler)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeunloadHandler)
  },
  methods: {
    handleWholeDataArr(val) {
      this.articleExArr = []
      this.$store.commit('SET_RECORD_ARR', [])
      if (!Array.isArray(val)) return
      const tempArr = val.filter(item => item.content !== '')
      if (tempArr.length === 0) {
        // this.$message.warning('无法生成：' + (this.isChEn ? '中文练习' : '英文练习'))
        return
      }
      while (this.articleExArr.length < 50) {
        this.articleExArr = this.articleExArr.concat(tempArr)
      }
      this.dealRecordArr()
      this.currentIndex = 0
      this.tempIndex = 0
    },
    dealRecordArr() {
      const recordArrTemp = JSON.parse(JSON.stringify(this.$store.state.typing.recordArr))
      const length = this.articleExArr.length - recordArrTemp.length
      if (length > 0) {
        for (let i = 0; i < length; i++) {
          recordArrTemp.push({
            totalChar: 0, // 输入字符总数
            correctChar: 0, // 正确字符个数
            errorChar: 0, // 错误字符个数
            backspace: 0 // 退格键次数
          })
        }
        this.$store.commit('SET_RECORD_ARR', recordArrTemp)
      }
    },
    stopTyping() {
      this.currentIndex = -1
    },
    continueTyping() {
      this.currentIndex = this.tempIndex
    },
    nextRow(toIndex, toIndexVal) {
      this.currentIndex = toIndex
      this.tempIndex = toIndex

      this.currentIndexInit = toIndexVal && toIndexVal !== '' ? toIndexVal : ''

      if (toIndex > 2) {
        document.querySelector('.art-scroll').style.top = ((toIndex - 2) * -77) + 'px'
      } else {
        document.querySelector('.art-scroll').style.top = '0px'
      }
      if (this.articleExArr.length - toIndex < 10) {
        this.articleExArr = this.articleExArr.concat(this.articleExArr)
        this.dealRecordArr()
      }
      if (this.currentIndex === this.articleExArr.length) {
        // 更改后的逻辑这里走不到了，就是走不到最后一行了
        this.currentIndex = -1
        this.tempIndex = -1
        // 建议打字完成后，离开当前页面
        return
      }
      if (this.currentIndex < 0) {
        this.currentIndex = 0
        this.tempIndex = 0
      }
    },
    stopF5Refresh() {
      // const doc = document.getElementsByClassName('art-whole')[0]
      // doc.onkeydown = function (e) {
      //   var evt = window.event || e;
      //   var code = evt.keyCode || evt.which;
      //   //屏蔽F1---F12
      //   if (code > 111 && code < 124) {
      //     if (evt.preventDefault) {
      //       evt.preventDefault();
      //     } else {
      //       evt.keyCode = 0;
      //       evt.returnValue = false;
      //     }
      //   }
      // };
      // //禁止鼠标右键菜单
      // doc.oncontextmenu = function () {
      //   return false;
      // };
      // 阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
      // history.pushState(null, null, window.location.href);
      // window.addEventListener("popstate", function () {
      //   history.pushState(null, null, window.location.href);
      // });
    },
    beforeunloadHandler(e) {
      e = e || window.event
      if (e) {
        e.returnValue = '您是否确认离开此页面-您输入的数据可能不会被保存'
      }
      return '您是否确认离开此页面-您输入的数据可能不会被保存'
    }
  }
}
</script>
<style scoped lang="less">
.art-whole {
  min-height: 545px;
  max-height: 545px;
  position: relative;
  overflow: hidden;
  .art-scroll {
    position: absolute;
    top: 0;
    left: 2%;
    width: 96%;
    .art-row-box {
      height: 77px;
    }
  }
}
</style>
