import request from '@/utils/request'

// 查询打字记录排行榜
export function listRecordRanking(query) {
    return request({
        url: 'api/typing/record/ranking',
        method: 'get',
        params: query
    })
}

// 新增打字记录
export function addRecord(data) {
    return request({
        url: 'api/typing/record',
        method: 'post',
        data: data
    })
}
