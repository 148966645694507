import request from '@/utils/request'

// 查询打字管理列表
export function listArticle(query) {
  return request({
    url: '/api/typing/article/list',
    method: 'get',
    params: query
  })
}

// 查询打字管理详细
export function getArticle(id) {
  return request({
    url: '/api/typing/article/' + id,
    method: 'get'
  })
}

// 查询打字记录
export function getRecordList(params) {
  return request({
    url: '/api/typing/record/list',
    method: 'get',
    params
  })
}
