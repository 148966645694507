<template>
  <div class="typing-record">
    <img src="../../../assets/images/typing_daojishi.png" alt="">
    <p class="time-text">倒计时</p>
    <p class="time-num">{{ getMin() }} : {{ getSeconds() }}</p>
    <div class="record_total">
      <p class="record-item">
        <img class="item-img" src="../../../assets/images/typing_sudu.png" alt="">
        <span class="item-title">速度</span>
        <span>{{ getSpeed() }}<span class="item-unit">{{ getSpeedUnit() }}</span></span>
      </p>
      <p class="record-item">
        <img class="item-img" src="../../../assets/images/typing_zhengque.png" alt="">
        <span class="item-title">正确率</span>
        <span>{{ getAccuracy() }}<span class="item-unit">%</span></span>
      </p>
      <p class="record-item">
        <img class="item-img" src="../../../assets/images/typing_cuowu.png" alt="">
        <span class="item-title">错误</span>
        <span>{{ getErrorChar() }}<span class="item-unit">字</span></span>
      </p>
      <p class="record-item">
        <img class="item-img" src="../../../assets/images/typing_caozuo.png" alt="">
        <span class="item-title">退格</span>
        <span>{{ getBackChar() }}<span class="item-unit">次</span></span>
      </p>
      <p class="record-item">
        <img class="item-img" src="../../../assets/images/typing_shuzihua.png" alt="">
        <span class="item-title">总字数</span>
        <span>{{ getTotalChar() }}<span class="item-unit">字</span></span>
      </p>
      <p class="record-btn">
        <el-button size="small" @click="continueTyping">{{ falseStart ? '暂停' : '继续' }}</el-button>
        <el-button size="small" @click="reset">重置</el-button>
        <el-button size="small" type="primary" @click="quit">退出</el-button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MessageBox } from 'element-ui'

export default {
  name: 'TypingSetting',
  components: {},
  created() {
    this.init()
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      article: state => state.typing.article,
      record: state => state.typing.record,
      recordArr: state => state.typing.recordArr
    }),
    getMin() {
      return () => {
        if (this.remainingTime > 0) {
          return this.to2(Math.floor(this.remainingTime / 60))
        } else {
          this.clearTimer()
          return '--'
        }
      }
    },
    getSeconds() {
      return () => {
        if (this.remainingTime > 0) {
          return this.to2(Math.floor(this.remainingTime % 60))
        } else {
          this.clearTimer()
          return '--'
        }
      }
    },
    getSpeed() {
      return () => {
        let num = 0
        if (!Array.isArray(this.recordArr) || this.recordArr.length === 0) return 0
        this.recordArr.forEach(record => {
          num += record.correctChar
        })
        const time = this.totalDuration - this.remainingTime
        let speed = 0
        if (num !== undefined && num !== null && num > 0 && time > 0) {
          speed = Math.floor(num * (60 / time))
        }
        // return this.to2(speed)
        return speed
      }
    },
    getAccuracy() {
      return () => {
        let cNum = 0
        let tNum = 0
        if (!Array.isArray(this.recordArr) || this.recordArr.length === 0) return 0
        this.recordArr.forEach(record => {
          cNum += record.correctChar
          tNum += record.totalChar
        })
        let accuracy = 0
        if (tNum !== undefined && tNum !== null && cNum !== undefined && cNum !== null) {
          if (tNum > 0 && cNum > 0) {
            accuracy = Math.floor(cNum / tNum * 100)
          }
        }
        // return this.to2(accuracy)
        return accuracy
      }
    },
    getErrorChar() {
      return () => {
        let num = 0
        if (!Array.isArray(this.recordArr) || this.recordArr.length === 0) return num
        this.recordArr.forEach(record => {
          num += record.errorChar
        })
        return num
      }
    },
    getBackChar() {
      return () => {
        let num = 0
        if (!Array.isArray(this.recordArr) || this.recordArr.length === 0) return num
        this.recordArr.forEach(record => {
          num += record.backspace
        })
        return num
      }
    },
    getTotalChar() {
      return () => {
        let num = 0
        if (!Array.isArray(this.recordArr) || this.recordArr.length === 0) return num
        this.recordArr.forEach(record => {
          num += record.totalChar
        })
        return num
      }
    },
    getSpeedUnit() {
      return () => {
        const lang = this.article.articleLang
        let unit = 'KPM'
        if (lang !== undefined && lang !== null) {
          if (lang === '0') {
            unit = 'WPM'
          }
          if (lang === '1') {
            unit = 'KPM'
          }
        }
        return unit
      }
    }
  },
  watch: {
    '$store.state.typing.isStart'(newVal) {
      if (newVal) {
        if (this.remainingTime > 0) {
          this.isStart = newVal
          this.setTimer()
        } else {
          // this.clearTimer()
          // todo 看看是否有必要加 时间不正确的提示
        }
      }
    }
  },
  data() {
    return {
      totalDuration: 0,
      remainingTime: 0,
      timer: null,
      isStart: false, // 用于真正开始后的判断
      falseStart: true // 控制打字区域的禁用
    }
  },
  methods: {
    init() {
      console.log('record init')
      this.totalDuration = this.article.setTime * 60
      this.remainingTime = this.article.setTime * 60
      this.clearTimer()
    },
    setTimer() {
      this.timer = setInterval(() => {
        this.remainingTime--
        if (this.remainingTime <= 0) {
          this.$emit('end')
          this.clearTimer()
        }
      }, 1000)
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    continueTyping() {
      this.falseStart = !this.falseStart
      if (this.isStart) {
        if (this.falseStart) {
          this.setTimer()
        } else {
          this.clearTimer()
        }
      }
      this.$emit('continue', this.falseStart)
    },
    reset() {
      const isStart = this.$store.state.typing.isStart
      if (isStart) {
        MessageBox.confirm('重置将不保存当前打字记录，是否继续？', '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
        ).then(() => {
          this.$emit('reset')
        }).catch(() => {
        })
      }
    },
    quit() {
      MessageBox.confirm('退出将不保存当前打字记录，是否继续？', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        // this.$emit('end')
        // todo 这里要使用下面的代码，上面为测试
        this.$store.commit('SET_ARTICLE', {})
        this.$store.commit('SET_RECORD', {})
        this.$store.commit('SET_IS_START', false)
        this.$router.push('/typingSetting')
      }).catch(() => {
      })
    },
    to2(val) {
      return val >= 10 ? val : '0' + val
    }
  }
}
</script>

<style scoped lang="less">
.typing-record {
  background-color: #5198fb;
  border-radius: 10px;
  padding-top: 15px;

  .time-text {
    font-size: 12px;
    color: #c0bdbd;
    padding-top: 10px;
  }
  .time-num {
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    padding: 10px 0 20px 0;
  }

  .record_total {
    width: 100%;
    //height: 200px;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    text-align: left;
    .record-item {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      .item-img {
        height: 20px;
        margin: -5px 10px;
      }
      .item-title {
        display: inline-block;
        width: 70px;
        color: #c0bdbd;
      }
      .item-unit {
        font-size: 12px;
        color: #c0bdbd;
      }
    }
    .record-btn {
      text-align: center;
    }
  }
}
</style>
