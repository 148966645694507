<template>
  <div class="art-row">
    <div class="row-pro">
      <span class="row-span">
        <ArticleCol
          v-for="(colData, index) in articleCellArr"
          :key="index"
          :col-data="colData"
        />
      </span>
    </div>
    <div class="row-in">
      <el-input
        ref="rowInput"
        v-model="rowInData"
        class="row-input"
        :class="{ 'row-input-disabled': !isActive }"
        type="text"
        @input="judgeRowInData"
        @dragenter.native.capture.prevent="handleDragEnter"
        @paste.native.capture.prevent="handleFalse"
        @copy.native.capture.prevent="handleFalse"
        @cut.native.capture.prevent="handleFalse"
        @keyup.enter.native="nextRow"
        @keydown.delete.native="prveRow"
        @keyup.delete.native="handleDeleteUp"
        @keydown.native="handleKeyDown"
        @keyup.native="handleInput"
      />
      <div
        class="row-div"
        :class="{ 'row-div-disabled': isActive }"
      />
    </div>
  </div>
</template>
<script>
import ArticleCol from './ArticleCol'
import { mapState } from 'vuex'

export default {
  name: 'ArticleRow',
  components: {
    ArticleCol
  },
  props: {
    rowData: {
      type: Object,
      require: true
    },
    rowIndex: {
      type: Number,
      require: true
    },
    rowIndexInit: {
      type: String,
      default: ''
    },
    currentIndex: {
      type: Number,
      require: true
    },
    isChEn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rowExData: '',
      rowSpace: '0px',
      rowSpaceLength: 0,
      indentUnit: 12.5,
      articleCellArr: [],
      rowInData: '',
      rowInOldData: '',
      isActive: false, // 是否处于激活状态
      totalDurationOri: 300, // 总时长-原始值
      totalDuration: 0, // 总时长
      totalTimer: null,
      maxSpeed: 0, // 峰值速度
      isCtrlPressed: false, // ctrl键是否已按下
      inputKeys: [192, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 189, 187, 81, 87, 69, 82, 84, 89, 85, 73, 79, 80, 219, 221, 65, 83, 68, 70, 71, 72, 74, 75, 76, 186, 222, 90, 88, 67, 86, 66, 78, 77, 188, 190, 191],
      isInputCh: false, // 是否正在使用中文输入法
      inputChLength: 0,
      tipFlag: true,
      hadDragEnter: false,
      keyArr: []
    }
  },
  watch: {
    rowData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.handleRowData(val)
      }
    },
    currentIndex: {
      immediate: true,
      handler(newVal) {
        this.handleCurrentIndex(newVal)
      }
    }
  },
  created() {
    this.handleCurrentIndex(this.currentIndex)
    this.$nextTick(function() {
      const span = document.getElementsByClassName('row-span')[this.rowIndex]
      const input = document.getElementsByClassName('row-input')[this.rowIndex].getElementsByTagName('input')[0]
      span.style.paddingLeft = this.rowSpace
      input.style.textIndent = this.rowSpace
    })
  },
  computed: {
    ...mapState({
      recordInfo: state => state.typing.record,
      isStart: state => state.typing.isStart
    })
  },
  methods: {
    handleRowData(val) {
      this.rowExData = val.content
      this.rowSpaceLength = val.spaceLength
      this.rowSpace = val.spaceLength * this.indentUnit + 'px'
      this.articleCellArr = val.content.split('').map(item => {
        return {
          item: item,
          arriveFlag: false,
          correctFlag: true
        }
      })
      if (!this.isStart) {
        this.rowInData = ''
        this.rowInOldData = ''
      }
    },
    // judgeRowInData() {
    //   // rowInOldData：旧值，旧值的作用在于判断当前字符串的变化是否为有效输入，并判断增加还是减少
    //   // 判断是否为有效输入
    //   if (this.rowInData === this.rowInOldData) return
    //   if (this.rowInData.length > this.articleCellArr.length) {
    //     // this.rowInData只截取有用的部分
    //     if (this.rowInOldData.length === this.articleCellArr.length) {
    //       this.rowInData = this.rowInOldData
    //       return
    //     } else {
    //       this.rowInData = this.rowInData.substr(0, this.articleCellArr.length)
    //     }
    //   }
    //   this.isInputCh = false
    //   this.inputChLength = 0
    //
    //   // 判断字符改变 增加或减少
    //   const flag = this.rowInData.length > this.rowInOldData.length
    //   const newIndex = this.rowInData.length - 1
    //   const oldIndex = this.rowInOldData.length - 1
    //   const changeTotalLength = Math.abs(newIndex - oldIndex)
    //   let correctLength = 0
    //   let errorLength = 0
    //   let backspaceLength = 0
    //
    //   if (flag) {
    //     // 增加字符的处理 ： 增加的字符挨个去比对
    //     for (let i = oldIndex + 1; i < newIndex + 1; i++) {
    //       let obj = this.articleCellArr[i]
    //       obj.arriveFlag = true
    //       obj.correctFlag = obj.item === this.rowInData.charAt(i)
    //       if (obj.correctFlag) {
    //         correctLength++
    //       } else {
    //         errorLength++
    //       }
    //     }
    //   } else {
    //     // 减少字符的处理 ： 减少的字符位置数据还原
    //     for (let i = newIndex + 1; i < oldIndex + 1; i++) {
    //       let obj = this.articleCellArr[i]
    //       if (obj.correctFlag) {
    //         correctLength++
    //       } else {
    //         errorLength++
    //       }
    //       obj.arriveFlag = false
    //       obj.correctFlag = false
    //       backspaceLength++
    //     }
    //   }
    //   // 同步输入值到旧值
    //   this.rowInOldData = this.rowInData
    //
    //   // 讲对比结果及时存入store
    //   let tempRecord = JSON.parse(JSON.stringify(this.recordInfo))
    //   if (flag) {
    //     tempRecord.totalChar += changeTotalLength
    //     tempRecord.correctChar += correctLength
    //     tempRecord.errorChar += errorLength
    //   } else {
    //     tempRecord.totalChar -= changeTotalLength
    //     tempRecord.correctChar -= correctLength
    //     tempRecord.errorChar -= errorLength
    //     tempRecord.backspace += backspaceLength
    //   }
    //   this.$store.commit('SET_RECORD', tempRecord)
    //   if (!this.isStart) {
    //     this.$store.commit('SET_IS_START', true)
    //   }
    // },
    judgeRowInData() {
      if (this.hadDragEnter) {
        this.rowInData = this.rowInOldData
      }
      // rowInOldData：旧值，旧值的作用在于判断当前字符串的变化是否为有效输入，并判断增加还是减少
      // 判断是否为有效输入
      if (this.rowInData === this.rowInOldData) return
      const recordArr = JSON.parse(JSON.stringify(this.$store.state.typing.recordArr))
      let recordObj = {
        totalChar: 0, // 输入字符总数
        correctChar: 0, // 正确字符个数
        errorChar: 0, // 错误字符个数
        backspace: 0 // 退格键次数
      }
      if (Array.isArray(recordArr) && recordArr.length >= this.rowIndex + 1) {
        recordObj = JSON.parse(JSON.stringify(recordArr[this.rowIndex]))
      }
      let nextRowInputData = ''
      if (this.rowInData.length > this.articleCellArr.length) {
        // this.rowInData只截取有用的部分，剩下的给到下一行
        nextRowInputData = this.rowInData.substring(this.articleCellArr.length)
        this.rowInData = this.rowInData.substr(0, this.articleCellArr.length)
      }
      this.isInputCh = false
      this.inputChLength = 0

      const totalCharLength = this.rowInData.length
      let correctLength = 0
      let errorLength = 0
      let backspaceLength = recordObj.backspace

      for (let i = 0; i < this.articleCellArr.length; i++) {
        const obj = this.articleCellArr[i]
        if (i < this.rowInData.length) {
          obj.arriveFlag = true
          obj.correctFlag = obj.item === this.rowInData.charAt(i)
          if (obj.correctFlag) {
            correctLength++
          } else {
            errorLength++
          }
        } else {
          if (obj.arriveFlag) {
            backspaceLength++
          }
          obj.arriveFlag = false
          obj.correctFlag = false
        }
      }

      recordObj.totalChar = totalCharLength
      recordObj.correctChar = correctLength
      recordObj.errorChar = errorLength
      recordObj.backspace = backspaceLength
      recordArr[this.rowIndex] = recordObj

      if (!this.isStart) {
        this.$store.commit('SET_IS_START', true)
      }
      this.$store.commit('SET_RECORD_ARR', recordArr)
      // 同步输入值到旧值
      this.rowInOldData = this.rowInData

      if (nextRowInputData !== '') {
        this.$emit('next', this.rowIndex + 1, nextRowInputData.trim())
      }
    },
    handleFalse() {
      return false
    },
    handleDragEnter() {
      this.hadDragEnter = true
      return false
    },
    handleKeyDown(event) {
      this.hadDragEnter = false
      // TAB键
      if (event.keyCode === 9) {
        event.preventDefault()
      }
    },
    handleInput(event) {
      if (this.inputKeys.indexOf(event.keyCode) > 0 && this.rowInData === '') {
        this.isInputCh = true
        this.inputChLength++
      }
    },
    nextRow() {
      // 这里的if判断也可以改成判断articleCellArr的arriveFlag是否都为true
      if (this.rowInData.length === this.articleCellArr.length) {
        this.$emit('next', this.rowIndex + 1)
      }
    },
    prveRow() {
      if (this.isInputCh) {
        if (this.inputChLength > 0) {
          this.inputChLength--
          return
        }
        this.isInputCh = false
      }
      if (this.rowInData === '') {
        this.rowInData = ''
        this.rowInOldData = ''
        this.$emit('next', this.rowIndex - 1)
        if (this.tipFlag && this.rowIndex - 1 < 0) {
          this.tipFlag = false
          this.$message.info('已经是第一行了')
        }
      }
    },
    handleDeleteUp() {
      this.tipFlag = true
    },
    handleCurrentIndex(val) {
      this.isActive = val === this.rowIndex
      if (this.isActive) {
        const timer = setInterval(() => {
          if (this.$refs.rowInput) {
            if (this.rowIndexInit && this.rowIndexInit !== '') {
              this.rowInData = this.rowIndexInit
              this.judgeRowInData()
            }
            this.$refs.rowInput.focus()
            clearInterval(timer)
          }
        }, 10)
      }
    }
  }
}
</script>
<style scoped lang="less">
.art-row {
  .row-pro {
    padding: 5px 3px;
    white-space: nowrap;
    text-align: left;
    //font-family: "宋体" !important;
    font-family: "typing-custom" !important;
  }
  .row-in {
    white-space: nowrap;
    .row-input {
    }
    .row-input-disabled ::v-deep .el-input__inner {
      background-color: #F5F7FA !important;
      border-color: #E4E7ED !important;
      color: #C0C4CC !important;
    }
    .row-div {
      width: 100%;
      height: 40px;
      position: relative;
      top: -40px;
      z-index: 999;
    }
    .row-div-disabled {
      z-index: -999;
    }
    ::v-deep .row-input input {
      //font-family: "宋体" !important;
      font-family: "typing-custom" !important;
      cursor: text;
      padding: 5px 2px;
      font-size: 20px;
      font-weight: initial;
    }
  }
}
</style>
